import React from "react";

export type Thresholds<T> = Array<[number, T]>;

export function useScrollThresholds<T>(thresholds: Thresholds<T>): T | null {
  const [current, setCurrent] = React.useState<T | null>(null);

  React.useEffect(() => {
    function callback(): void {
      const value = thresholds.reduce<T | null>(
        (prev, [threshold, value]) =>
          window.scrollY >= threshold ? value : prev,
        null
      );

      if (value !== current) {
        setCurrent(value);
      }
    }

    window.addEventListener("scroll", callback);

    return (): void => window.removeEventListener("scroll", callback);
  }, [thresholds, current]);

  return current;
}
