import React from "react";

export function useViewportHeight(): number {
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    setHeight(window.innerHeight);
  }, []);

  return height;
}
