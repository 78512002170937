import React from "react";
import {
  useRefPosition,
  useViewportHeight,
  useScrollThresholds,
  Thresholds,
} from "../hooks";
import {ImageState} from "./ImageState";
import {CaseBlock} from "./CaseBlock";

interface Case {
  client: string;
  title: string;
  url: string;
  promotional_description: string;
  provided_services: string[];
  image1x: string;
}

interface CasesDisplayBlockProps {
  cases: Case[];
}

function useCompiledImageStateThresholds(
  cases: Case[],
): {ref: any; thresholds: Thresholds<ImageState>} {
  const {ref, y1, y2} = useRefPosition();
  const vh = useViewportHeight();
  const thresholds: Thresholds<ImageState> = [];

  if (y1 && y2 && vh) {
    thresholds.push([y1, ImageState.Absolute()]);

    for (let i = 0; i < cases.length; i++) {
      const factor = i + Math.max(i - 1, 0);
      const topOffset = y1 + vh * factor * 0.5;

      thresholds.push([topOffset, ImageState.Fixed(i)]);
    }

    thresholds.push([y2 - vh, ImageState.Absolute(cases.length - 1)]);
  }

  return {ref, thresholds};
}

export function CasesDisplayBlock(props: CasesDisplayBlockProps): JSX.Element {
  const {ref, thresholds} = useCompiledImageStateThresholds(props.cases);
  const imgState = useScrollThresholds(thresholds);
  const imgStateIndex = (imgState && imgState.index) || 0;
  const imgStateFixed = (imgState && imgState.fixed) || false;
  const sectionClasses = [
    "cases-display-block",
    imgStateFixed && "cases-display-block--fixed",
  ].join(" ");

  return (
    <section className={sectionClasses} ref={ref}>
      {props.cases.map((c, i) => (
        <CaseBlock
          key={i}
          client={c.client}
          title={c.title}
          url={c.url}
          description={c.promotional_description}
          services={c.provided_services}
          images={[[0, c.image1x]]}
          zIndex={props.cases.length - i}
          isPassed={imgStateIndex > i}
        />
      ))}
    </section>
  );
}
