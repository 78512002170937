import React from "react";

enum ScrollDirection {
  Up,
  Down
}

export function useScrollDirection(): ScrollDirection {
  const [direction, setDirection] = React.useState(ScrollDirection.Down);

  React.useEffect(() => {
    let _scrollTop = 0;
    let _direction = ScrollDirection.Down;

    function callback(): void {
      if (window.scrollY > _scrollTop && _direction === ScrollDirection.Up) {
        setDirection(ScrollDirection.Down);
        _direction = ScrollDirection.Down;
      } else if (
        window.scrollY < _scrollTop &&
        _direction === ScrollDirection.Down
      ) {
        setDirection(ScrollDirection.Up);
        _direction = ScrollDirection.Up;
      }

      _scrollTop = window.scrollY;
    }

    window.addEventListener("scroll", callback);

    return (): void => window.removeEventListener("scroll", callback);
  }, []);

  return direction;
}
