import React from "react";
import Slider from "react-slick";

interface SlideshowBlockProps {
  slides: any[];
  autoplay: boolean;
}

export function SlideshowBlock({
  slides,
  autoplay
}: SlideshowBlockProps): JSX.Element {
  return (
    <Slider
      dots={true}
      infinite={true}
      className="slideshow-block"
      speed={500}
      autoplay={autoplay}
    >
      {slides.map((slide, i) => (
        <div key={i}>
          <picture>
            <img src={slide.image} />
          </picture>
        </div>
      ))}
    </Slider>
  );
}
