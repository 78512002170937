export function debounce<T extends CallableFunction>(fn: T, delay: number): T {
  let timeout: NodeJS.Timeout;

  function wrapper(...args: any[]): any {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  }

  return (wrapper as any) as T;
}
