import React from "react";
import { debounce } from "../utils";

interface WindowSize {
  width: number;
  height: number;
}

export function useWindowResize(delay = 100): WindowSize {
  const initialSize: WindowSize = { width: 0, height: 0 };

  if (typeof window !== "undefined") {
    initialSize.width = window.innerWidth;
    initialSize.height = window.innerHeight;
  }

  const [size, setSize] = React.useState(initialSize);

  React.useEffect(() => {
    const callback = debounce(function() {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }, delay);

    window.addEventListener("resize", callback);

    return (): void => window.removeEventListener("resize", callback);
  }, [delay]);

  return size;
}
