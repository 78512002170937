import React from "react";
import ReactDOM from "react-dom";
import { CaseBlock } from "./components/CaseBlock";
import { CasesDisplayBlock } from "./components/CasesDisplayBlock";
import { SlideshowBlock } from "./components/SlideshowBlock";

declare global {
  interface Window {
    _SVC_RENDER_DATA: string;
  }
}

interface Renders {
  [id: string]: {
    name: string;
    props: any;
  };
}

interface ComponentRegistry {
  [name: string]: React.FunctionComponent<any>;
}

const components: ComponentRegistry = {
  CaseBlock,
  CasesDisplayBlock,
  SlideshowBlock
};

const renders: Renders = JSON.parse(window._SVC_RENDER_DATA);

for (const renderId in renders) {
  const root = document.getElementById(renderId);
  const { name, props } = renders[renderId];
  const Component = components[name];
  ReactDOM.hydrate(<Component {...props} />, root);
}
