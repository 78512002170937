// Get reference to all language selectors <select>
const languageSelectors = document.querySelectorAll(
  ".languageSelector"
) as NodeListOf<HTMLInputElement>;

for (const languageSelector of Array.from(languageSelectors)) {
  // Listen to changes  on the select
  languageSelector.addEventListener("change", async () => {
    const data = new FormData();

    data.append("language", languageSelector.value);

    const resp = await fetch("/i18n/setlang/", {
      method: "POST",
      body: data,
      headers: {
        // Set the requested with header so that Django will recognize
        // that this is an Ajax request
        "X-Requested-With": "XMLHttpRequest"
      }
    });

    // If succesful, Django will return a 204 (since it's a Ajax request)
    if (resp.status === 204) {
      window.location.href = (window as any).I18N_INFO[languageSelector.value];
    }
  });
}
