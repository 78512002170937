import React from "react";
import { useWindowResize } from "./useWindowResize";

interface RefPosition {
  ref: React.Ref<any>;
  y1: number | null;
  y2: number | null;
}

export function useRefPosition(): RefPosition {
  const ref = React.useRef<any>();
  const { width, height } = useWindowResize(300);
  const [position, setPosition] = React.useState({ y1: null, y2: null });

  React.useEffect(() => {
    const el = ref.current;

    if (!el) {
      return;
    }

    function boundSetPosition(): void {
      setPosition({
        y1: el.offsetTop,
        y2: el.offsetTop + el.offsetHeight
      });
    }

    boundSetPosition();

    window.addEventListener("load", boundSetPosition);

    return (): void => window.removeEventListener("load", boundSetPosition);
  }, [width, height]);

  return {
    ref,
    y1: position.y1,
    y2: position.y2
  };
}
