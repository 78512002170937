const menuButton = document.getElementById("menu-button");
const menuMobile = document.getElementById("menu-mobile");

if (menuButton && menuMobile) {
  menuButton.addEventListener("click", () => {
    if (menuButton.classList.contains("is-active")) {
      menuButton.classList.remove("is-active");
      menuMobile.classList.remove("is-active");
    } else {
      menuButton.classList.add("is-active");
      menuMobile.classList.add("is-active");
    }
  });
}
