import React from "react";

type ImageSources = [number, string];

interface CaseBlockProps {
  client: string;
  title: string;
  url: string;
  description: string;
  services: string[];
  images: ImageSources[];
  zIndex: number;
  isPassed: boolean;
}

export function CaseBlock({
  client,
  title,
  url,
  description,
  services,
  images,
  zIndex,
  isPassed,
}: CaseBlockProps): JSX.Element {
  return (
    <div
      className={[
        "cases-display-block__case",
        isPassed && "cases-display-block__case--passed",
      ].join(" ")}
      style={{zIndex}}>
      <a href={url}>
        <div className="cases-display-block__content">
          <h3>{client}</h3>
          <h2>{title}</h2>
          <p>{description}</p>
          <ul className="cases-display-block__provided-services">
            {services.map((service, k) => (
              <li key={k}>{service}</li>
            ))}
          </ul>
        </div>
        <div className="cases-display-block__img">
          <picture>
            {images.map((image, i) => (
              <source
                key={i}
                srcSet={image[1]}
                media={`(min-width: ${image[0]}px)`}
              />
            ))}
            <img src={images[0][1]} alt={title} />
          </picture>
        </div>
      </a>
    </div>
  );
}
