export class ImageState {
  static Absolute(index?: number): ImageState {
    return new ImageState("absolute", index || 0);
  }

  static Fixed(index: number): ImageState {
    return new ImageState("fixed", index);
  }

  position: "fixed" | "absolute";
  index: number;

  get fixed(): boolean {
    return this.position === "fixed";
  }

  get absolute(): boolean {
    return this.position === "absolute";
  }

  constructor(position: "fixed" | "absolute", index: number) {
    this.position = position;
    this.index = index;
  }
}
